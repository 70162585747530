import React, {useEffect, useState} from 'react';
import './css/tailwind.css';
import './css/global.css';
import {api, baseUrl, requests} from "./api";
import {images} from "./components/assets/images";
import {useLocation} from "react-router-dom";

function App() {
  const [isReceived, setIsReceived] = React.useState(false);
  const [imageGift, setImageGift] = React.useState<string>("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const phone = searchParams.get("phone");
  const code = searchParams.get("code");

  console.log("phone", phone);
  console.log("code", code);
  const [showBoxGift, setShowBoxGift] = useState(true);


  const withdrawGift = async () => {

    try {
      const response = await requests({
        url: api.withdrawGift(),
        method: "POST",
        data: {
          code_invoice: code,
        },
      });
      console.log("response", response);
      setImageGift(`${baseUrl}${response?.gift?.image}`);
      setIsReceived(true);

    } catch (error) {
      console.error("Error withdrawing gift:", error);

    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBoxGift(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [showBoxGift]);
  return (


    <div className="page bg-main relative min-h-screen ">
      <img src={images.bg_bottom_left} className="absolute bottom-0 left-0 w-[100px]" alt="" />
      <img src={images.bg_top_right} className="absolute  top-0 right-0  w-[100px]" alt="" />

      <div className="absolute top-[50px] z-10 w-full flex justify-center left-0">
        {/* <img src={images.banner_mini_gamer} alt="" className='max-h-[150px]' onClick={() => setIsReceived(false)} /> */}
        <img src={images.banner_mini_gamer} alt="" className='max-h-[150px]' />
      </div>

      {
          !isReceived && (
              <div className="absolute top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full flex justify-center">
                <img src={images.qua_nguoi_than} alt="" className='w-[70%]' />
              </div>

          )
      }

      {
          !isReceived && (
              <div className="absolute bottom-10 w-full left-0 flex justify-center">
                <img src={images.btn_rut_qua} alt="" onClick={withdrawGift} />
              </div>
          )
      }
      {/*{isReceived && <FireworksEffect trigger={true} />}*/}

      {
          isReceived && (
              <>
                <div className="absolute top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full flex justify-center">
                  {showBoxGift ? (
                      <div className="text-center flex justify-center">
                        <img src={images.box_gift} alt="" className="shake" />
                      </div>
                  ) : (
                      <img src={imageGift} alt="" className='w-[70%] animate-zoom-rotate' />
                  )}
                </div>
                <div className="absolute bottom-10 w-full left-0 flex justify-center">
                  <img src={images.button_nhan_qua} alt="" />
                </div>
              </>
          )
      }

    </div>
  );
}

export default App;
